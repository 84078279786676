<template>
  <div>
    <Table
      label="Post Shares"
      :actions="actions"
      :items="$store.getters['postShares/tableData']"
      :fields="fields"
      :loading="$store.state.postShares.loadingTable"
      :pagination="$store.state.postShares.tablePagination"
      :sort-options="sortOptions"
      @load="loadData"
      @paginate="paginate"
    />
  </div>
</template>

<script>
import Table from '@/layouts/components/Table.vue'

export default {
  components: {
    Table,
  },
  data() {
    return {
      selectedData: '',
      sortOptions: [],

      // Fields
      fields: [
        {
          key: 'id',
          type: 'id',
          label: 'ID',
        },
        {
          key: 'authorUser_nickname',
          label: 'User',
          type: 'profile',
          image: 'authorUser_avatar',
          sortable: false,
        },
        {
          key: 'email',
          type: 'email',
          label: 'Email',
        },
      ],
    }
  },
  mounted() {
    this.loadData()
  },
  methods: {
    loadData() {
      const { id } = this.$route.params
      return this.$store.dispatch('postShares/getTableData', id)
    },
    paginate(val) {
      this.$store.commit('postShares/SET_TABLE_PAGINATION', val)
    },
  },
}
</script>
